<template>
  <div class="QRCode" :style="{ width: size + 'px' }">
    <div ref="container"></div>
  </div>
</template>
<script>
import QrCode from 'arale-qrcode'
export default {
  props: {
    content: String,
    size: {
      default: 120
    }
  },
  watch: {
    content(val) {
      if (val) this.createFn()
    }
  },
  methods: {
    createFn() {
      this.$refs.container.innerHTML = ''
      this.$refs.container.appendChild(
        new QrCode({
          text: this.content,
          size: this.size
        })
      )
    }
  },
  mounted() {
    if (this.content) this.createFn()
  }
}
</script>
<style scoped>
.QRCode {
  margin: 0 auto;
}
</style>
